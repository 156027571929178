import { Auction } from "api/models/auction";
import { File } from "api/models/file";
import { LotStatus } from "api/models/lotStatus";
import { LotSummary } from "api/models/lotSummary";
import { Page } from "api/models/page";
import { GetLotsQuery } from "api/requests/getLotsQuery";
import { useAuctionsService } from "api/services/auctionsService";
import { useLotsService } from "api/services/lotsService";
import Drawer from "components/drawer";
import LotsFilters from "components/filter/LotsFilters";
import LotsListView from "components/lot/LotsListView";
import Spinner from "components/spinner";
import { Accordion, Button, Pagination, Popover } from "flowbite-react";
import { neatDateWithWeekday } from "formatters/date";
import useLotsSearchParams from "hooks/lotsSearchParams";
import { useEffect, useState } from "react";
import { HiOutlineDocumentArrowDown, HiOutlineDocumentPlus, HiOutlinePlus } from "react-icons/hi2";
import { Link, useParams } from "react-router-dom";
import { ViewBreakpoints } from "static-data/constants";
import { useAppSelector } from "store";
import { useSlug } from "utils/slugHelper";

const AuctionDetailsPage = () => {
  const { getLots } = useLotsService();
  const { getAuctionById } = useAuctionsService();
  const { auctionSlug } = useParams();
  const { getSlugHash } = useSlug();
  const auctionId: number = getSlugHash(auctionSlug);
  const [auction, setAuction] = useState<Auction>(null);
  const [isLoadingAuction, setIsLoadingAuction] = useState(true);
  const [isLoadingLots, setIsLoadingLots] = useState(true);
  const categories = useAppSelector((state) => state.staticData.categories);
  const countries = useAppSelector((state) => state.staticData.countries);
  const [lots, setLots] = useState<Page<LotSummary>>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showAuctionDetailsDrawer, setShowAuctionDetailsDrawer] = useState(false);
  const { params: lotsSearchParams, setPage, queryString } = useLotsSearchParams();
  const { search, country: selectedCountries, category: selectedCategories, status: selectedStatuses, isReserved, page, sortBy, sortDirection } = lotsSearchParams;

  const allowedLotStatuses: LotStatus[] = ["Inactive", "Active"];
  const defaultSortBy = sortBy ?? "endsAtUtc";

  const fetchData = async () => {
    let fetchedAuction = auction;
    if (!fetchedAuction) {
      setIsLoadingAuction(true);
      fetchedAuction = (await getAuctionById(auctionId)).data;
      setAuction(fetchedAuction);
    }
    setIsLoadingAuction(false);

    if (fetchedAuction) {
      await fetchLots();
    }
  }

  const fetchLots = async () => {
    setIsLoadingLots(true);

    const lotsQuery: GetLotsQuery = {
      auctionId: auctionId,
      categoryIds: selectedCategories?.map(c => +c),
      countryCodes: selectedCountries,
      statuses: selectedStatuses,
      isReserved: (isReserved === undefined || isReserved === null || isNaN(isReserved)) ? null : !!isReserved,
      text: search,
      includeAuction: true,
      includeLastBid: true,
      pageIndex: page ? page - 1 : 0,
      pageSize: 10,
      sortBy: defaultSortBy,
      sortDescending: sortDirection === "desc"
    };

    const lotsData = (await getLots(lotsQuery))
    setLots(lotsData.data);
    setTotalPages(Math.trunc((lotsData.data.totalCount + lotsQuery.pageSize - 1) / lotsQuery.pageSize));
    setIsLoadingLots(false);
  };

  useEffect(() => {
    const handleResize = (() => window.innerWidth < ViewBreakpoints.xl ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    fetchData();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId, queryString]);

  const documentDownloadTemplate = (file: File) =>
    <Popover key={file.id} content={<p className="w-24 text-sm text-gray-500 px-3 py-2">Descarcă</p>} trigger="hover" placement="bottom">
      <Link to={file.url} target="_blank" className="flex items-center gap-2 cursor-pointer underline underline-offset-2 font-medium text-sm hover:text-brand-500">
        <HiOutlineDocumentArrowDown className="shrink-0" />
        <span className="truncate">{file.displayFileName}</span>
      </Link>
    </Popover>

  const auctionDetailsAccordion =
    auction && <div className="overflow-y-auto overflow-x-hidden no-scrollbar rounded-lg bg-gray-50 border-2 py-4 px-3">
      <Accordion arrowIcon={HiOutlinePlus} alwaysOpen flush>
        <Accordion.Panel isOpen={true}>
          <Accordion.Title className="text-base font-semibold">
            Detalii licitație
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col justify-around gap-4">
              <article className="space-y-2">
                <p className="font-medium text-sm">Data începerii</p>
                <p className="font-normal text-xs">{neatDateWithWeekday(auction.startDateUtc)}</p>
              </article>
              <article className="space-y-2">
                <p className="font-medium text-sm">Data închiderii ultimului lot</p>
                <p className="font-normal text-xs">{neatDateWithWeekday(auction.endDateUtc)}</p>
              </article>
              <article className="pt-4 space-y-2">
                {auction.contract && auction.contract.url && documentDownloadTemplate(auction.contract)}
                {auction.documents &&
                  auction.documents.map((doc) => doc && doc.url && documentDownloadTemplate(doc))}
              </article>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title className="text-base font-semibold">
            Descriere
          </Accordion.Title>
          <Accordion.Content>
            <article className="py-2 font-normal text-sm text-pretty">
              {auction.description}
            </article>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title className="text-base font-semibold">
            Vizionare
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col gap-4">
              {auction.viewingStartsAtUtc && auction.viewingEndsAtUtc &&
                <p className="font-normal text-sm text-pretty">
                  De<span className="font-semibold text-sm"> {neatDateWithWeekday(auction.viewingStartsAtUtc)} </span> <br />
                  până<span className="font-semibold text-sm"> {neatDateWithWeekday(auction.viewingEndsAtUtc)}</span>
                </p>}
              {auction.countryCityLocations.length > 0 &&
                <article className="space-y-2">
                  <p className="font-semibold text-sm">Locații</p>
                  {auction.countryCityLocations.map((loc, index) =>
                    <p key={index} className="font-normal text-xs">{loc.city}, {loc.countryName}</p>
                  )}
                </article>}
            </div>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title className="text-base font-semibold">
            Ridicare
          </Accordion.Title>
          <Accordion.Content>
            {auction.pickUpStartsAtUtc && auction.pickUpEndsAtUtc &&
              <p className="py-2 font-normal text-sm text-pretty">
                De<span className="font-semibold text-sm"> {neatDateWithWeekday(auction.pickUpStartsAtUtc)} </span> <br />
                până<span className="font-semibold text-sm"> {neatDateWithWeekday(auction.pickUpEndsAtUtc)}</span>
              </p>}
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>;

  if (isLoadingAuction) {
    return <Spinner />;
  }

  return (
    <div className="w-full flex flex-col xl:flex-row gap-4">

      <Drawer isOpen={showAuctionDetailsDrawer && isSmallScreen} onClose={() => setShowAuctionDetailsDrawer(false)}>
        {auctionDetailsAccordion}
      </Drawer>

      {!isSmallScreen && <div className="h-full min-w-80 w-80">
        {auctionDetailsAccordion}
      </div>}

      <div className="bg-lightPrimary w-full min-w-0 flex-col mx-auto space-y-2">
        <h3 className="mt-2 ml-2 md:ml-4 font-semibold text-xl md:text-2xl xl:text-3xl truncate">{auction.name}</h3>
        {isSmallScreen &&
          <Button size="sm" outline color="light"
            onClick={() => setShowAuctionDetailsDrawer(true)}
            className="text-white rounded-lg w-full">
            <HiOutlineDocumentPlus className="mr-2 h-5 w-5" />Detalii licitație
          </Button>}

        <LotsFilters withCategories={categories} withCountries={countries} withStatuses={allowedLotStatuses} withIsReserved={true} />

        {isLoadingLots
          ? <Spinner />
          : <>
            {lots && <LotsListView lotSummaries={lots.items} />}

            {lots && totalPages > 1 && <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={(page) => setPage(page)}
              showIcons
              nextLabel="Înainte"
              previousLabel="Înapoi"
              className="flex justify-center md:float-right" />}
          </>}
      </div>
    </div>
  );
};

export default AuctionDetailsPage;
