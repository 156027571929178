import { Footer } from 'flowbite-react';
import { BsFacebook, BsInstagram, BsTiktok } from "react-icons/bs";

const FooterContent = () => {
  return (
    <Footer container>
      <div className="flex">
        <Footer.Copyright href="/" by="Licitas" year={2024} />
        <div className="flex space-x-2 ml-2">
          <Footer.Icon href="https://www.facebook.com/licitasauctions" target="_blank" icon={BsFacebook} />
          <Footer.Icon href="https://www.instagram.com/licitas.ro" target="_blank" icon={BsInstagram} />
          <Footer.Icon href="https://www.tiktok.com/@licitas5" target="_blank" icon={BsTiktok} />
        </div>
      </div>
      <Footer.LinkGroup>
        <Footer.Link href={process.env.REACT_APP_ASSETS_LOCATION + 'PrivacyPolicy.pdf'} target="_blank">Politica de confidențialitate</Footer.Link>
        <Footer.Link href={process.env.REACT_APP_ASSETS_LOCATION + 'GeneralConditions.pdf'} target="_blank">Condiții generale</Footer.Link>
        {/* TODO Use as={Link} feature for Footer.Links after Flowbite fix for this issue is merged - check here https://github.com/themesberg/flowbite-react/pull/1244 */}
        <Footer.Link href="/contact">Contact</Footer.Link>
      </Footer.LinkGroup>
    </Footer>
  );
};

export default FooterContent;
