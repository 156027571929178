import * as Sentry from "@sentry/react";
import AuthProvider from "auth/AuthProvider";
import customTheme from 'components/theme/CustomTheme';
import { Flowbite } from 'flowbite-react';
import NetworkErrorHandler from "handlers/NetworkErrorHandler";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import store from "store";
import App from "./App";
import "./index.scss";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: process.env.REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS.split(','),
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter
    future={
      {
        v7_relativeSplatPath: true,
        v7_startTransition: true
      }
    }
  >
    <AuthProvider>
      <Provider store={store}>
        <Flowbite theme={{ theme: customTheme }}>
          <NetworkErrorHandler>
            <App />
          </NetworkErrorHandler>
        </Flowbite>
      </Provider>
    </AuthProvider>
  </BrowserRouter>
);
